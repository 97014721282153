import  { type ReactNode, type HTMLProps } from 'react'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export const Section = forwardRef<HTMLDivElement, { className?: string; children: ReactNode; direction: 'row' | 'col' } & HTMLProps<HTMLDivElement>>(({ className, children, direction, ...props }, ref) => {
	const commonClasses = 'flex flex-col w-full py-8 xl:py-12 3xl:py-24 4xl:py-32 5xl:py-48'
	const rowClasses = 'md:flex-row items-center justify-center md:justify-between gap-8 md:gap-12 lg:gap-16 xl:gap-20 2xl:gap-24 3xl:gap-28 4xl:gap-52'
	const colClasses = 'items-center gap-2 3xl:gap-4 4xl:gap-8 5xl:gap-16'
	return (
		<section className={twMerge(commonClasses, direction === 'row' ? rowClasses : colClasses, className)} ref={ref} {...props}>
			{children}
		</section>
	)
})
